import React from 'react';
import Navbar from './component/Navbar/Navbar';
import ParticlesBanner from './component/Banner/ParticlesBanner';
import About from './component/About';
import Service from './component/Service';
import Contact from './component/Contact';
import Footer from './component/Footer';
import jhonData from './component/jhonData';


export const Home3 = () => (
  <div className="body_wrapper">
      <Navbar mClass="dark_menu" mContainer="custome_container"/>
      <ParticlesBanner jhonData={jhonData}/>
      <About aClass='about_area bg_color' jhonData={jhonData}/>
      <Service wClass="work_area_two" jhonData={jhonData}/>
      <Contact jhonData={jhonData}/>
      <Footer jhonData={jhonData}/>
  </div>
)


