import React, {Component} from 'react';
import TeamItem from './TeamItem.js';

class Team extends Component {
	render(){
		let jhonData = this.props.jhonData; 
		let team = jhonData.about_team;
		let displayTeam = () => {
			return (
				team.map( teamItem =>
					<TeamItem key={teamItem.id}
							teamItem={teamItem}/>
				)
			)
		};    
	    return(       
	        <div className="grid row">
	        	{displayTeam()}
	       </div>       
	    );	
	}    
}

export default Team;