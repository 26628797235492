import React, {Component} from 'react';

function TeamItem(props) {
	return(
		<div className="col-md-6 col-sm-3 col-xs-6 col-6 grid-item">
            <div className="about_team hover-style">
                <img src={require('../../image/' + props.teamItem.image)} alt=""/>
                <div className="item-img-overlay">
                    <div className="overlay-info text-center">
                        <h6 className="sm-titl">{props.teamItem.name}</h6>
                        <div className="icons-team">
                            <a href={props.teamItem.linkedIn}><i className="fab fa-linkedin fa-lg"></i></a>
                        </div>                                                    
                    </div>
                </div>                      
            </div>
        </div>
	)
}

export default TeamItem;
