const jhonData = {
    "header": "",
    "name": "WanderCodes AS",
    "role": "Software development company",
    "subheader": "Software development company",
    "ptext": "Software development company headquartered in Oslo Norway.",
    "name_subtitle": "Software development company headquartered in Oslo Norway.",
    "header_services": ["Mobile apps","UI/UX Design", "Cloud architecture"],
    "socialLinks":[
        {
          "name": "Linked in",
          "url": "https://www.linkedin.com/company/wandercodes/",
          "className": "social_linkedin"
        },
        {
          "name":"medium",
          "url":"https://medium.com/wandercodes",
          "className":"fab fa-medium-m"
        }
    ],
    "about_us_title": "About Us",
    "about_us_subtitle": "WanderCodes AS",
    "about_us_details": "WanderCodes revolutionizes raw ideas into significant digital solutions utilizing leading edge technical expertise. We have been developing engineering software and mobile applications since 2012 with in-depth experience in banking, airline, e-commerce, and ridesharing apps.",
    "about_team":[
        {
          id: 1,
          name: "THEODORE GONZALEZ",
          image: "ted-bw2.png",
          linkedIn: "https://www.linkedin.com/in/gonzalezted/"
        },
        {
          id: 2,
          name: "IAN TIMOTHY GONZALEZ",
          image: "tim-bw2.png",
          linkedIn: "https://www.linkedin.com/in/timothy-gonzalez/"
        }
    ],
    "section_3_title": "What we do",
    "section_3_subtitle": "We don't just do things. We do them well with the customer in mind.",
    "service":[
        {
            "id" : 1,
            "iconName": "icon-desktop",
            "serviceTitle": "Design",
            "sDetails" : "We build user centric products."
        },
        {
            "id" : 6,
            "iconName": "icon-tools",
            "serviceTitle": "We’re up to date",
            "sDetails" : "We use the latest tools and best practices."
        },
        {
            "id" : 3,
            "iconName": "icon-presentation",
            "serviceTitle": "Testing and automation",
            "sDetails" : "We believe that automation is an important investment as you grow."
        },
        {
            "id" : 4,
            "iconName": "icon-linegraph",
            "serviceTitle": "Analytics",
            "sDetails" : "We gather data responsibly and use it to make decisions"
        },
        {
            "id" : 5,
            "iconName": "icon-pencil",
            "serviceTitle": "Support",
            "sDetails" : "We value customer service and feedback."
        },

    ],
    "section_4_title": "What we have done",
    "section_4_subtitle": "section_4_subtitle",
    "contact":[
        {
            "id": "1",
            "title": "Contact Info",
            menuItems: [
            {
              id: 1,
              icon: 'icon_pin_alt',
              text: 'Address: Rostockgate 24, Oslo Norway',
            },
            {
              id: 2,
              icon: 'icon_phone',
              text: 'Phone: +4741233646',
            },
            {
              id: 3,
              icon: 'icon_mail',
              text: 'Email: hello@wandercodes.com',
            },
            {
              id: 4,
              icon: 'icon_globe',
              text: 'Website: http://wandercodes.com',
            }
            ]
        },
    ],
    contacttitle: 'Talk to us',
    "footer": ""
}

export default jhonData;

